import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject, Subscription, interval, merge } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InactivityTriggerService {
  private logoutTime = 30 * 60 * 1000; // 30 minutes in milliseconds
  private inactivityTime = 15 * 60 * 1000; // 15 minutes in milliseconds
  private checkInterval = 5 * 1000; // Check every 5 seconds
  private timerSubscription: Subscription;
  private lastActivityTime = Date.now();
  private inactivityTrigger = new Subject<void>();
  private logoutTrigger = new Subject<void>();
  private refreshCounter = 0;
  tokenRefreshed = new EventEmitter<void>();

  constructor() {}

  startMonitoring() {
    this.refreshCounter = 0;
    this.tokenRefreshed.subscribe(() => {
      this.refreshCounter++;
    });

    const inactivityCheck = interval(this.checkInterval);
    // const keydownEvents = fromEvent(document, 'keydown'); // Keyboard events commented out for now (not captured in stories)
    // const mouseEvents = fromEvent(document, 'mousemove'); // Mouse events commented out for now (not captured in stories)
    // Merge keydown and mouse events with timer to reset on any activity after captured in user stories

    this.timerSubscription = merge(inactivityCheck).subscribe(() => {
      if (Date.now() - this.lastActivityTime >= this.logoutTime) {
        // 15 minutes of inactivity
        this.logoutTrigger.next();
      } else if (Date.now() - this.lastActivityTime >= this.inactivityTime && this.refreshCounter >= 2) {
        // 5 minutes of inactivity after 2 refreshes
        this.logoutTrigger.next();
      } else if (Date.now() - this.lastActivityTime >= this.inactivityTime && this.refreshCounter < 2) {
        // every 5 minutes of inactivity upto 2 refreshes
        this.inactivityTrigger.next();
      }
    });
  }

  // Reset the activity time to the current time
  resetActivity() {
    this.lastActivityTime = Date.now();
  }

  // Observable to subscribe to for inactivity
  onInactivity(): Observable<void> {
    return this.inactivityTrigger.asObservable();
  }

  // Observable to subscribe to for logout
  onLogout(): Observable<void> {
    return this.logoutTrigger.asObservable();
  }

  stopMonitoring() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }
}
